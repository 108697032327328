<template>
  <form-validation-field
    ref="formValidationField"
    :name="validationField.name"
    :validation="{ detectInput: false, ...validationField.validation }"
    v-bind="validationField"
  >
    <template #field="{ validation: { valid, validated }}">
      <quill-editor
        ref="quillEditor"
        :class="{ 'is-invalid': validated && !valid}"
        :value="value"
        v-bind="$attrs"
        @input="handleInput"
      />
    </template>
  </form-validation-field>
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import { quillEditor } from 'vue-quill-editor'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

export default {
  components: {
    FormValidationField,
    quillEditor,
  },
  props: {
    value: {
      type: [Array, File, String],
      default: null,
    },
    validationField: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async checkIfValid() {
      // Get the text without the html characters.
      const text = this.$refs.quillEditor.quill.getText()

      const validation = await this.$refs.formValidationField.$refs.validation.validate(text)

      return validation.valid
    },
    handleInput(value) {
      this.$emit('input', value)

      this.checkIfValid()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill';

.quill-editor.is-invalid {
  .ql-toolbar.ql-snow, .ql-container {
    border-color: #ea5455 !important;
  }
}
</style>
