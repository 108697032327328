<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    :default-submit-buttons="false"
    @before-validation="$refs.formValidationQuillEditor.checkIfValid()"
    @validated="submit"
  >
    <Modal
      scrollable
      size="lg"
      footer-class="news-article-footer"
      :title="`${isNew ? 'Add' : 'Edit'} News`"
      :value="value"
      @show="handleShow"
      @hidden="handleHidden"
      v-on="$listeners"
    >
      <template #modal-footer>
        <span
          v-if="isNew"
          class="mr-auto text-muted"
        >
          News entry {{ totalEntries + 1 }} of {{ $store.state.event.event.news_articles_limit }}
        </span>

        <submit-buttons
          :loading="form.isLoading"
          :invalid="observer.failed || isLoading || sliderLimitIsReached || entriesLimitReached"
          @save="$refs.validationForm.validate()"
          v-on="$listeners"
        />
      </template>

      <b-alert
        variant="danger"
        class="mb-2"
        :show="sliderLimitIsReached || entriesLimitReached"
      >
        <h2 class="alert-heading">
          {{ alertMessage.heading }}
        </h2>

        <div class="alert-body">
          {{ alertMessage.body }}
        </div>
      </b-alert>

      <b-row>
        <!-- Type -->
        <b-col sm="6">
          <form-validation-news-article-type
            v-model="form.type"
            rules="required"
            mode="lazy"
            @change="handleChangeType"
          />
        </b-col>
      </b-row>

      <section v-if="form.type">
        <!-- Logo -->
        <image-input
          v-if="form.type !== 'info'"
          label="Photo"
          ideal-file-dimensions="800 X 500"
          :file.sync="form.photo"
          :preview-src.sync="form.photo_url"
        />

        <b-row>
          <!-- Title -->
          <b-col cols="12">
            <form-validation-field
              v-model="form.title"
              name="title"
              label="Title*"
              aria-autocomplete="none"
              rules="required"
            />
          </b-col>

          <!-- Content -->
          <b-col cols="12">
            <form-validation-quill-editor
              ref="formValidationQuillEditor"
              v-model="form.content"
              :options="editorOption"
              :validation-field="{
                name: 'content',
                label: `Content${sliderIsSelected ? '' : '*'}`,
                rules: sliderIsSelected ? `max:${maxCharacters}`: 'required',
                validation: {
                  customMessages: {
                    max: `The slider's content field may not be greater than ${maxCharacters} characters`
                  }
                }
              }"
            />
          </b-col>

          <!-- Publish -->
          <b-col cols="12">
            <b-form-checkbox
              v-model="publish"
              class="mt-1"
              name="publish"
              @change="handleChangePublish"
            >
              Publish
            </b-form-checkbox>
          </b-col>
        </b-row>
      </section>

    </Modal>
  </validation-form>
</template>

<script>
import {
  BCol, BRow, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import Modal from '@/components/modals/Modal.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import FormValidationNewsArticleType from '@/components/forms/validation/FormValidationNewsArticleType.vue'
import FormValidationQuillEditor from '@/components/forms/validation/FormValidationQuillEditor.vue'
import ImageInput from '@/components/forms/images/ImageInput.vue'

import Form from '@/forms/Form'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormCheckbox,
    FormValidationField,
    FormValidationNewsArticleType,
    FormValidationQuillEditor,
    ValidationForm,
    ImageInput,
    Modal,
    SubmitButtons,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    newsArticle: {
      type: Object,
      default: () => ({}),
    },
    totalEntries: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      maxCharacters: 400,
      editorOption: {
        bounds: '.modal-body',
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
          ],
        },
      },
      slider: {
        limit: 4,
        items: [],
      },
      publish: false,
      form: new Form({
        type: null,
        title: '',
        photo: undefined,
        photo_url: null,
        content: '',
        // publish: true,
        published_on: null,
      }, true),
    }
  },
  computed: {
    isNew() {
      return Object.keys(this.newsArticle).length === 0
    },
    isSlider() {
      return this.newsArticle.type === 'slider'
    },
    isAlreadyPublishedSlider() {
      return this.newsArticle.published_on && this.isSlider
    },
    sliderIsSelected() {
      return this.form.type === 'slider'
    },
    sliderLimitIsReached() {
      return this.sliderIsSelected && this.publish && !this.isAlreadyPublishedSlider && this.slider.items.length >= this.slider.limit
    },
    entriesLimitReached() {
      return this.isNew && this.totalEntries >= this.$store.state.event.event.news_articles_limit
    },
    alertMessage() {
      if (this.sliderLimitIsReached) {
        return {
          heading: 'Slider Limit Reached',
          body: `You can only have ${this.slider.limit} published slider articles at a time.`,
        }
      }

      return {
        heading: 'News Limit Reached',
        body: 'You have reached your news limit. Contact us to increase your limit.',
      }
    },
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    // Only add a published date when the article is first published.
    updatePublishedOn() {
      if (!this.publish) {
        this.form.published_on = null
        return
      }

      if (this.newsArticle.published_on) {
        return
      }

      this.form.published_on = new Date().toLocaleString('en-US', {
        year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
      })
    },
    async getSliderAmount() {
      // Check cache.
      if (this.slider.items.length || this.isAlreadyPublishedSlider) {
        return
      }

      this.isLoading = true

      // Check the amount of articles that are sliders to see if slider limit is reached.
      try {
        const { data } = await this.$http.get(`/events/${this.$store.state.event.event.id}/news-articles?type=slider&published=true`)

        this.slider.items = data.data
      } catch (error) {
        flashError(error)
      }

      this.isLoading = false
    },
    handleShow() {
      Object.assign(this.form, this.newsArticle)

      if (this.newsArticle.published_on) {
        this.publish = true
      }
    },
    handleHidden() {
      this.slider.items = []
      this.publish = false
      this.form.reset(true)

      this.$emit('hidden')
    },
    async handleChangeType() {
      this.$refs.validationForm.$refs.observer.reset()

      if (this.sliderIsSelected && !this.isSlider) {
        await this.getSliderAmount()
      }

      // Only validate when there is content. This is so it does not validate when user adds a new article.
      if (this.form.content) {
        this.$nextTick(() => {
          this.$refs.formValidationQuillEditor.checkIfValid()
        })
      }
    },
    async handleChangePublish() {
      if (this.sliderIsSelected && this.publish) {
        await this.getSliderAmount()
      }
    },
    submit() {
      const update = !this.isNew
      let event = 'add-news-article'
      let url = `/events/${this.$store.state.event.event.id}/news-articles`

      if (update) {
        url = `/news-articles/${this.newsArticle.id}`
        event = 'update-news-article'
      }

      this.updatePublishedOn()

      this.form.post(url, false, update)
        .then(response => {
          const { data } = response

          this.$emit(event, {
            ...this.newsArticle,
            ...data,
          })
        })
    },
  },
}
</script>

<style lang="scss">
  .ql-editor {
    min-height: 200px;
  }
</style>
