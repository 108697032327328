<template>
  <section>
    <!-- News Article -->
    <news-article
      v-model="modal"
      :news-article="newsArticle"
      :total-entries="metaData.total"
      @cancel="modal = false"
      @hidden="newsArticle = {}"
      @add-news-article="handleAddNewsArticle"
      @update-news-article="handleUpdateNewsArticle"
    />

    <b-card no-body>
      <card-header-filters
        title="News"
        :filters="filters"
        @add="modal = true"
        @reset-filters="handleResetFilters"
      >
        <template #filters>
          <!-- Type Filter -->
          <select-news-article-type
            v-model="filters.type"
            default-selected="All"
            @input="getNewsArticles"
          />

          <!-- Published Filter -->
          <b-form-group
            label="Publish Status"
            label-for="publish-filter"
          >
            <b-form-select
              v-model="filters.publish"
              name="publish-filter"
              :options="['All', { text: 'Published', value: true }, { text: 'Not Published', value: false }]"
              @input="getNewsArticles"
            />
          </b-form-group>
        </template>
      </card-header-filters>

      <!-- News Articles List -->
      <table-list
        primary-key="id"
        item-name="news article"
        link-cell-key="title"
        :items="filteredNewsArticles"
        :fields="fields"
        :endpoints="{ delete: '/news-articles' }"
        :busy="isLoading"
        :meta-data="metaData"
        :delete-modal="({ item }) => ({
          afterDelete: () => handleDeleteNewsArticle(item)
        })"
        @link-clicked="handleShowNewsArticle"
        @change-per-page="getNewsArticles"
        @change-pagination="updatePage"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import TableList from '@/components/TableList.vue'
import NewsArticle from '@/views/events/website/news/EventWebsiteNewsArticle.vue'
import SelectNewsArticleType from '@/components/forms/validation/FormValidationNewsArticleType.vue'

import useFlash from '@/composables/useFlash'
import { formatDate, title } from '@core/utils/filter'

const { flashError } = useFlash()

export default {
  name: 'EventWebsiteNewsArticles',
  components: {
    BCard,
    BFormGroup,
    BFormSelect,
    CardHeaderFilters,
    TableList,
    NewsArticle,
    SelectNewsArticleType,
  },
  data() {
    return {
      isLoading: true,
      modal: false,
      newsArticle: {},
      newsArticles: [],
      metaData: {
        currentPage: 1,
        perPage: localStorage.getItem('perPage') || 10,
      },
      fields: [
        'title',
        {
          key: 'type',
          formatter: value => title(value),
        },
        {
          key: 'published_on',
          formatter: value => this.dateFormatter(value),
        },
      ],
      filters: {
        type: 'All',
        publish: 'All',
      },
    }
  },
  computed: {
    filteredNewsArticles() {
      return this.newsArticles.filter(newsArticle => !this.newsArticleIsFiltered(newsArticle))
    },
  },
  created() {
    this.getNewsArticles()
  },
  methods: {
    dateFormatter(value) {
      return formatDate(value, true, {
        month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',
      })
    },
    updatePage(pageNumber) {
      this.metaData.currentPage = pageNumber
      this.getNewsArticles()
    },
    buildQuery() {
      const query = new URLSearchParams({
        per_page: this.metaData.perPage,
        page: this.metaData.currentPage,
      })

      if (this.filters.type !== 'All') {
        query.append('type', this.filters.type)
      }

      if (this.filters.publish !== 'All') {
        query.append('published', this.filters.publish)
      }

      return query
    },
    async getNewsArticles() {
      this.isLoading = true

      try {
        const { data } = await this.$http.get(`events/${this.$store.state.event.event.id}/news-articles?${this.buildQuery().toString()}`)

        this.newsArticles = data.data
        this.metaData = {
          currentPage: data.meta.current_page,
          perPage: data.meta.per_page,
          total: data.meta.total,
        }
      } catch (error) {
        flashError(error)
        this.$Progress.fail()
      }

      this.isLoading = false
      this.$Progress.finish()
    },
    newsArticleIsFiltered(newsArticle) {
      let filtered = false

      // News article type doesn't match filter.
      if (this.filters.type !== 'All' && newsArticle.type !== this.filters.type) {
        filtered = true
      }

      return filtered
    },
    sortNewsByPublishedOnDate() {
      this.newsArticles.sortByDate('desc', 'published_on')
    },
    handleResetFilters(filters) {
      this.filters = filters
      this.getNewsArticles()
    },
    updateNewsArticle(newsArticle) {
      this.newsArticles.splice(
        this.newsArticles.findIndex(n => n.id === newsArticle.id),
        1,
        newsArticle,
      )
    },
    async handleShowNewsArticle(newsArticle) {
      // Fetch the content for the news article.
      if (newsArticle.content === undefined) {
        try {
          const { data } = await this.$http.get(`/news-articles/${newsArticle.id}`)
          // eslint-disable-next-line no-param-reassign
          newsArticle = data.data

          // Update the news article with the content to avoid re-fetching once loaded.
          this.updateNewsArticle(newsArticle)
        } catch (error) {
          flashError(error)
        }
      }

      this.newsArticle = newsArticle
      this.modal = true
    },
    resetModal() {
      this.modal = false
      this.newsArticle = {}
    },
    handleAddNewsArticle(newsArticle) {
      if (!this.newsArticleIsFiltered(newsArticle)) {
        this.newsArticles.push(newsArticle)
        this.metaData.total += +1
      }

      this.sortNewsByPublishedOnDate()
      this.resetModal()
    },
    handleUpdateNewsArticle(newsArticle) {
      this.updateNewsArticle(newsArticle)

      if (this.newsArticleIsFiltered(newsArticle)) {
        this.metaData.total += -1
      }

      this.sortNewsByPublishedOnDate()
      this.resetModal()
    },
    handleDeleteNewsArticle(newsArticle) {
      this.newsArticles.splice(this.newsArticles.findIndex(n => n.id === newsArticle.id), 1)
      this.metaData.total += -1
    },
  },
}
</script>

<style scoped>

</style>
