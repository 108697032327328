<template>
  <form-validation-field
    name="type"
    label="Type"
    component="BFormSelect"
    :options="options"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    FormValidationField,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    defaultSelected: {
      type: [Object, String],
      default: () => ({ text: 'Choose Type', value: null, disabled: true }),
    },
  },
  computed: {
    options() {
      const types = ['Article', 'Slider'].map(type => ({
        text: type,
        value: type.toLowerCase(),
      }))

      return [
        this.defaultSelected,
        ...types,
      ]
    },
  },
}
</script>

<style scoped>

</style>
